.login-container-parent {
    --ion-background-color: #346768;
}

.login-container {
    text-align: center;
    background-color: #346768;
}

.login-container img {
    height: 320px;
}

.login-info {
    width: 100%;
    position: absolute;
    bottom: 1em;
    text-align: center;
    color: #fafafa;
    padding: 0 1em;
}

.login-actions {
    padding: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
}

.login-actions img {
    padding-top: 6px;
    height: 64px;
}

.login-actions img:hover {
    cursor: pointer;
}

.login-info a {
    text-decoration: underline;
    color: #ffca00;
}

#login-footer {
    text-align: center;
}
