.daily-report {
    align-items: center;
}

.daily-report .card .content * {
    font-size: 1rem;
}

.daily-report .card .content h3 {
    font-size: 1.2rem;
}

.daily-report .card .assessment ion-thumbnail {
    --size: 160px;
}

.daily-report .card .assessment .assessment-content .assessment-card {
    width: 100%;
}

.daily-report .card .assessment .assessment-content .assessment-card .thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 360px;
    width: 100%;
    overflow-y: hidden;
}


.daily-report .card .assessment .assessment-content .assessment-card .thumbnail img {
    width: 100%;
}
